<template>
  <div class="w100p">
    <h1 class="text-left f2-5 ma0 f1-5-mb mt2-mb">Welcome Back!</h1>
    <p class="font-axiroma-book text-left mb4 text-grey f1-3 mt0  mb1-mb">Please Login to get access to the dashboard.</p>
    <div class="border-grey">
    <div class="display-flex border-bottom" :class="{ 'bg-grey br0-5 ': validEmail }">
      <bh-icon icon="envelope" class="text-grey inline f1-5 pa2 pa1-mb w6"/>
      <bh-input
        :value="user.email"
        type="email"
        placeholder="Email"
        inputClasses="pl1 f1-5"
        class="w100p"
        @output="updateValue(user, 'email', $event)"
      />
      <bh-icon
        v-if="user.email" :icon="validEmail ? 'check-mark' : 'exclamation'"
        class="inline f2 pa2 pb1 pa1-mb"
        :class="validEmail ? 'text-success': 'text-danger'"
      />
    </div>
    <div class="display-flex" :class="{ 'bg-grey br0-5 ': user.password }">
      <bh-icon icon="key" class="text-grey inline f1-5 pa2 pa1-mb w6"/>
      <bh-input
        type="password"
        :value="user.password"
        placeholder="Password"
        class="password w100p"
        inputClasses="pl1 f1-5"
        @output="updateValue(user, 'password', $event)"
        @keydown.enter="onSubmit"/>
    </div>
    </div>
    <div class="mt2 display-flex justify-between align-center">
      <router-link to="/forgot-password" class="forgot-password text-violet font-bold f1-5">Forgot Password?</router-link>
      <div class="flex text-right text-grey">
        <bh-checkbox class="checkbox f1-5" label="Remember me" classes="display-flex justify-end align-center"/>
      </div>
    </div>
    <p class="f1-5 text-danger" v-if="errorMessage">{{ errorMessage }}</p>
    <div class="mt5">
      <bh-button
        @output="onSubmit"
        :disabled="!validEmail || !user.password"
        btnClass="w100p h6 br0-5 bg-violet text-white f2 border-none">
        <bh-loader v-if="loading" color="#ffffff" />
        <div v-else class="display-flex justify-center align-center">
          <span class="mt0-7 mt0-5-mb">Access Dashboard</span>
          <bh-icon icon="right-arrow" class="inline f1-9 pl1"/>
        </div>
      </bh-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { validateEmail, updateValue } from '@/utils'

export default {
  name: 'tbh-login-form',
  data() {
    return {
      user: {
        email: '',
        password: ''
      },
      errorMessage: '',
      loading: false
    }
  },
  computed: {
    validEmail() {
      return validateEmail(this.user.email)
    }
  },
  methods: {
    ...mapActions(['loginToApp', 'fetchFeedbackReasons']),
    updateValue,
    /**
     * @method onSubmit
     * prevents page reload on submit
     * @param - {}
    */
    async onSubmit() {
      this.loading = true
      const setUserAndGoToRoute = (data) => {
        const validData = data && data.access_token && data.user
        if (validData) {
          const routeToGoTo = (this.$route['query'].redirect) ? this.$route['query'].redirect : '/'
          if (routeToGoTo) this.$router.push(routeToGoTo)
          this.fetchFeedbackReasons()
        } else {
          this.user.password = ''
          this.errorMessage = 'Access Denied'
        }
        this.loading = false
      }
      try {
        await this.loginToApp({ loginParams: this.user, callback: setUserAndGoToRoute })
      } catch (err) {
        this.errorMessage = err?.error?.message
        this.user.password = ''
        this.loading = false
      }
    }
  }
}
</script>
